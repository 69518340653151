import { BrowserRouter, Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Home from "./pages/Home";
import Category from "./pages/Category";
import Header from "./pages/Header";
import Footer from "./pages/Footer";
import Post from "./pages/Post";
import Contact from "./pages/Contact";
import Saved from "./pages/Saved";
import Notifications from "./pages/Notifications";
import Search from "./pages/Search";
import TermsConditions from "./pages/content/TermsConditions";
import PrivacyPolicy from "./pages/content/PrivacyPolicy";
import Disclaimer from "./pages/content/Disclaimer";
import Cookie from "./pages/content/Cookie";
import About from "./pages/content/About";
import Vacancies from "./pages/Vacancies";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <ScrollToTop />
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="/category" element={<Category />} />
          <Route path="/saved" element={<Saved />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/post" element={<Post />} />
          <Route path="/vacancies" element={<Vacancies />} />
          <Route path="/search" element={<Search />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/cookie" element={<Cookie />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
