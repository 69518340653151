import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <section className="py-6 light-bg-primary">
        <div className="container my-lg-8">
          <div className="row justify-content-center text-center">
            <div className="col-md-9 col-12 my-lg-1 my-4">
              <h2 className="display-5">
                Download Our App for Instant Updates
              </h2>
              <p className="lead px-lg-8 mb-6 ev-lh-normal">
                Get real-time alerts of Sarkari Result, Sarkari Naukri -
                Government Jobs, examination notifications, and results with our
                easy-to-use app.
              </p>
              <div className="d-grid d-md-block">
                <a
                  href="https://play.google.com/store/apps/details?id=com.ev.examsvacancy"
                  target="_blank"
                  className="btn btn-primary mb-2 mb-md-0 px-5"
                >
                  Download App
                </a>
                <Link
                  to="/about"
                  className="btn btn-outline-primary mb-2 mb-md-0 px-5 ms-lg-1 ms-0"
                >
                  About Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer className="pt-lg-8 pt-5 footer bg-white">
        <div className="container mt-lg-3">
          <div className="row my-2">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="mb-4">
                <img
                  src="/assets/images/logo.png"
                  height="38"
                  alt="Exams Vacancy"
                />
                <div className="mt-4">
                  <p>
                    Stay updated with the Sarkari Result, Sarkari Naukri
                    featuring real-time job vacancies, exam notifications, and
                    result updates.
                  </p>
                  <p>
                    Your one-stop source for all government job opportunities
                    and essential examination details.
                  </p>
                  <div className="fs-4 mt-5">
                    <h3 className="fw-bold mb-3">Social</h3>
                    <a
                      href="https://www.facebook.com/com.examsvacancy/"
                      target="_blank"
                      className="me-3 fs-3"
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a
                      href="https://x.com/examsvacancy/"
                      target="_blank"
                      className="me-3 fs-3"
                    >
                      <i className="bi bi-twitter-x"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/com.examsvacancy/"
                      target="_blank"
                      className="fs-3"
                    >
                      <i className="bi bi-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="offset-lg-1 col-lg-2 col-md-3 col-6">
              <div className="mb-4">
                <h3 className="fw-bold mb-3">Pages</h3>
                <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                  <li>
                    <Link to="/" className="nav-link">
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link to="/states" className="nav-link">
                      States
                    </Link>
                  </li>
                  <li>
                    <Link to="/departments" className="nav-link">
                      Departments
                    </Link>
                  </li>
                  <li>
                    <Link to="/saved" className="nav-link">
                      Saved Vacancy
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="nav-link">
                      Contact Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions" className="nav-link">
                      Terms & Condition
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" className="nav-link">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/disclaimer" className="nav-link">
                      Disclaimer
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.ev.examsvacancy"
                      target="_blank"
                      className="nav-link"
                    >
                      Get the app
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-6">
              <div className="mb-4">
                <h3 className="fw-bold mb-3">Links</h3>
                <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Latest Jobs",
                        categoryId: "5ydb3lom",
                      }}
                      className="nav-link"
                    >
                      Latest Jobs
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Results",
                        categoryId: "jbwm9i43",
                      }}
                      className="nav-link"
                    >
                      Results
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Admit Card",
                        categoryId: "Oj38G4ef",
                      }}
                      className="nav-link"
                    >
                      Admit Card
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Banking",
                        categoryId: "o90g88ug",
                      }}
                      className="nav-link"
                    >
                      Banking
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Police",
                        categoryId: "t1u9o16c",
                      }}
                      className="nav-link"
                    >
                      Police
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "UPSC",
                        categoryId: "7ze1aa3w",
                      }}
                      className="nav-link"
                    >
                      UPSC
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Engineer",
                        categoryId: "k3s3orr1",
                      }}
                      className="nav-link"
                    >
                      Engineer
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Teachers",
                        categoryId: "mewod5t6",
                      }}
                      className="nav-link"
                    >
                      Teachers
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/category"
                      state={{
                        categoryName: "Coast Guard",
                        categoryId: "s0lzv2h7",
                      }}
                      className="nav-link"
                    >
                      Coast Guard
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="mb-4">
                <h3 className="fw-bold">Stay in touch</h3>
                <p className="mb-1">
                  Subscribe to our newsletter to get Sarkari Result, Sarkari
                  Naukri updates.
                </p>
                <form className="mt-3">
                  <div className="input-group position-relative mb-3 d-flex align-items-center justify-content-end">
                    <input
                      type="text"
                      className="form-control ev-newsletter-input ps-3 fs-5 rounded-4 form-control-lg"
                      placeholder="Email ID"
                    />
                    <button
                      className="btn bg-dark text-white position-absolute m-1 z-5 rounded-4 px-4 fw-medium fs-5"
                      type="submit"
                    >
                      Subscribe
                    </button>
                  </div>
                </form>
                <div className="mt-5">
                  <h3 className="fw-bold">Download App</h3>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.ev.examsvacancy"
                    target="_blank"
                  >
                    <img
                      src="/assets/images/google-play.svg"
                      alt="Google Play"
                      className="img-fluid mt-2 rounded-3"
                      width="150"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <section className="border-top py-3 mt-6">
        <div className="container">
          <div className="row align-items-center g-0">
            <div className="col-lg-4 col-md-5 col-12">
              <span>© 2024 Exams Vacancy. All Rights Reserved</span>
            </div>
            <div className="col-12 col-md-7 col-lg-8 d-md-flex justify-content-end">
              <nav className="nav nav-footer">
                <Link className="nav-link ps-0" to="/privacy-policy">
                  Privacy Policy
                </Link>
                <Link to="/cookie" className="nav-link px-2 px-md-3">
                  Cookie Notice
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
